import ErrorLayout from "@layouts/ErrorLayout";
import { ApolloErrorType } from "@lib/errors/types";
import { GetStaticProps, NextPage } from "next";

type Props = {
  error?: ApolloErrorType;
};

const Custom404: NextPage<Props> = ({ error }) => <ErrorLayout error={error} />;

// Error pages are static pages and therefore must use getStaticProps
export const getStaticProps: GetStaticProps = () => {
  return {
    props: {
      title: "404: Page not found",
    },
  };
};

export default Custom404;
